.desktopGalleryContainer {
  margin-right: 100px;
  margin-left: 100px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 25px;
}

.mobileGalleryContainer {
  margin-left: 20px;
  margin-right: 20px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 15px;
}

.desktopGalleryContainer img {
  max-width: 400px;
}

.mobileGalleryContainer img {
  max-width: 150px;
}

@media (min-width: 1083px) {
  .desktopGalleryContainer {
    display: flex;
  }

  .mobileGalleryContainer {
    display: none;
  }
}
@media (max-width: 1083px) {
  .mobileGalleryContainer {
    display: flex;
  }
  .desktopGalleryContainer {
    display: none;
  }
}
