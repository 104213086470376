.contactContainer {
  display: flex;
  margin-left: 25px;
  margin-bottom: 25px;
  flex-direction: column;
}

.contactContainer a {
  color: black;
}
