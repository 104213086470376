.desktopNavContainer {
  flex-direction: row;
  gap: 30px;
  background-color: white;
  color: black;
  align-items: center;
}

.logoDesktopContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px 0px 5px 80px;
  cursor: pointer;
}

.rightContainer {
  margin-left: auto;
  margin-right: 80px;
  display: flex;
  align-items: center;
  gap: 25px;
}

.link {
  cursor: pointer;
  color: black;
  text-decoration: none;
}

@media (min-width: 1083px) {
  .desktopNavContainer {
    display: flex;
  }
  .mobileNavContainer {
    display: none;
  }
}
@media (max-width: 1083px) {
  .mobileNavContainer {
    display: flex;
  }
  .desktopNavContainer {
    display: none;
  }
}
