.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.imgText {
  position: absolute; /* position the text absolutely to the parent */
  top: 50%; /* place the top of the text in the middle of the container */
  left: 50%; /* place the left of the text in the middle of the container */
  transform: translate(-50%, -50%); /* center the text using translate */
  color: white;
  font-size: 38px;
  text-align: center;
  border-radius: 20px;
  background-color: #000;
  background: rgba(0, 0, 0, 0.5);
  padding: 5px;
}

/* For desktop */
@media (min-width: 1083px) {
  .container {
    flex-direction: row;
    gap: 25px;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .container a {
    width: 750px;
    height: 750px;
    position: relative; /* add position relative to parent for absolute position of child */
  }
  .container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media (max-width: 1083px) {
  /* For mobile */
  .container {
    flex-direction: column;
  }
  .container a {
    width: 100%;
    height: 200px;
    position: relative; /* add position relative to parent for absolute position of child */
    margin-bottom: 0;
  }
  .container img {
    width: 100%;
    height: 100%;
    object-position: 99% 1%;
    object-fit: cover;
  }

  .imgText {
    font-size: 18px;
  }
}
