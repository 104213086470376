.recognitionContainer {
  display: flex;
  margin-left: 25px;
  margin-bottom: 25px;
  flex-direction: row;
  flex-wrap: wrap;
}

.textContainer {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 0.5;
  width: min-content;
}

.imagesContainer {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}

.gfw {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.logo {
  width: 60px;
  height: 60px;
  margin-right: 5px;
}

.gmaro {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.collective {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.dallesgo {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.desktopGalleryContainer {
  margin-right: 80px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 25px;
}

.mobileGalleryContainer {
  margin-left: 50px;
  margin-right: 50px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 25px;
}

.desktopGalleryContainer img {
  max-width: 300px;
}

.mobileGalleryContainer img {
  max-width: 100px;
}

@media (min-width: 1083px) {
  .desktopGalleryContainer {
    display: flex;
  }

  .mobileGalleryContainer {
    display: none;
  }
}
@media (max-width: 1083px) {
  .textContainer {
    max-width: 150px;
  }
  .mobileGalleryContainer {
    display: flex;
  }
  .desktopGalleryContainer {
    display: none;
  }
}
