.desktopGalleryContainer {
  margin-right: 100px;
  margin-left: 100px;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  gap: 25px;
}

.mobileGalleryContainer {
  margin-left: 50px;
  margin-right: 50px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 25px;
}

.desktopGalleryContainer img {
  max-width: 1000px;
}

.desktopGalleryContainer .smallImg {
  max-width: 500px;
}

.mobileGalleryContainer img {
  max-width: 350px;
}

.title {
  text-align: center;
}

.description {
  text-align: center;
  margin-right: 100px;
  margin-left: 100px;
}

@media (min-width: 1083px) {
  .desktopGalleryContainer {
    display: flex;
  }

  .mobileGalleryContainer {
    display: none;
  }
}
@media (max-width: 1083px) {
  .mobileGalleryContainer {
    display: flex;
  }
  .desktopGalleryContainer {
    display: none;
  }
  .description {
    margin-right: 20px;
    margin-left: 20px;
  }
}
