@media (min-width: 1083px) {
  .desktopNavContainer {
    display: flex;
  }
  .mobileNavContainer {
    display: none;
  }
  .openCloseButtons {
    display: none;
  }
}
@media (max-width: 1083px) {
  .mobileNavContainer {
    display: flex;
  }
  .desktopNavContainer {
    display: none;
  }
  .openCloseButtons {
    display: flex;
  }
}

.navContainerWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.mobileNavContainer {
  flex-direction: row;
  gap: 10px;
  color: black;
  align-items: center;
  justify-content: center;
}

.openCloseButtons {
  position: absolute;
  width: 50px;
  justify-content: center;
  margin-left: 15px;
  border: 0;
  left: 0;
  background-color: white;
}

.logoMobileContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.menuItems {
  z-index: 21;
  position: absolute;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  top: 60px;
  list-style-type: none;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.menuItems > li > a {
  color: black;
  font-size: 15px;
  text-decoration: none;
}

.socialLinks {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.menuItems > hr {
  width: 75%;
}
