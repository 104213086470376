.footer {
  margin-top: auto;
  min-height: 100px;
  background-color: black;
  color: white;
}

.copyright {
  padding-top: 40px;
  display: block;
  text-align: center;
}
