.aboutContainer {
  display: flex;
  margin: 20px 360px 50px 400px;
  flex-direction: column;
  align-items: center;
}

.aboutContainer img {
  object-fit: contain;
}

.mobileAboutContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.mobileAboutContainer .aboutContentContainer {
  margin: 0px 20px 10px 20px;
  align-items: center;
  flex-direction: column;
}

.mobileAboutContainer img {
  width: 100px;
  object-fit: contain;
}

.aboutContentContainer {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

.aboutContainer p {
  font-size: 20px;
}

@media (max-width: 1700px) {
  .aboutContainer {
    margin: 20px 300px 50px 350px;
  }
}

@media (max-width: 1500px) {
  .aboutContainer {
    margin: 20px 220px 50px 280px;
  }
}

@media (max-width: 1300px) {
  .aboutContainer {
    margin: 20px 120px 50px 120px;
  }
}

@media (min-width: 1083px) {
  .aboutContainer {
    display: flex;
  }
  .mobileAboutContainer {
    display: none;
  }
}

@media (max-width: 1083px) {
  .aboutContainer {
    display: none;
  }
  .mobileAboutContainer {
    display: flex;
  }
}
