.imageWrapper {
  position: relative;
}

.imageOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0px;
  color: white;
}

.fullWidth {
  max-width: 1200px !important;
}

.smallImg {
  max-width: 500px !important;
}

@media (max-width: 1083px) {
  .smallImg {
    max-width: 350px !important;
  }
}

.portrait {
  width: 250px;
  height: 400px;
}

.portrait img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
